import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Switch, BrowserRouter, Route, useLocation, Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import { Button } from '@material-ui/core';
import { LayoutHeader } from '../layout-header/layout-header';
import { LayoutFooter } from '../layout-footer/layout-footer';
import { ScrollToTop } from '../scroll-to-top/scroll-to-top';
import { PagePackagesList } from '../page-packages-list/page-packages-list';
import { PagePackagesItem } from '../page-packages-item/page-packages-item';
import { PagePackagesHowToChoose } from '../page-packages-how-to-choose/page-packages-how-to-choose';
import { PageError404 } from '../page-error-404/page-error-404';
import { PageError } from '../page-error/page-error';
import { PageBasket } from '../page-basket/page-basket';
import { PagePaymentSuccess } from '../page-payment-success/page-payment-success';
import { PagePaymentFail } from '../page-payment-fail/page-payment-fail';
import { PageDelivery } from '../page-delivery/page-delivery';
import { PageTerms } from '../page-terms/page-terms';
import { PagePrivacyPolicy } from '../page-privacy-policy/page-privacy-policy';
import { PageRequisites } from '../page-requisites/page-requisites';
import { PageOrderItem } from '../page-order-item/page-order-item';
import { Splash } from '../splash/splash';
import { PRODUCT_TYPE } from '../../../models/product-type';

export const App = () => {
    const [basket, setBasket] = React.useState([]);
    const basketSize = basket.reduce((size, position) => size + position.quantity, 0);

    const addItemToBasket = (type, id) => {
        setBasket((prevBasket) => {
            for (let i = 0; i < prevBasket.length; i += 1) {
                const position = prevBasket[i];

                if (position.type === type && position.id === id) {
                    prevBasket[i].quantity = prevBasket[i].quantity + 1;
                    return prevBasket.slice();
                }
            }

            return [
                ...basket,
                { type, id, quantity: 1 },
            ];
        });
    };

    const removeItemFromBasket = (type, id) => {
        const newOrder = [];

        basket.forEach((item) => {
            if (item.type === type && item.id === id) {
                if (item.quantity > 1) {
                    newOrder.push({
                        ...item,
                        quantity: item.quantity - 1,
                    });
                }
            } else {
                newOrder.push(item);
            }
        });

        setBasket(newOrder);
    };

    return (
        <React.Fragment>
            <CssBaseline />
            <BrowserRouter>
                <ScrollToTop />
                <Switch>
                    <Route path="/" exact>
                        <LayoutHeader basket={basketSize} />
                        <Splash
                            class="page-packages-list__splash"
                            image="/img/splash/main1.jpg"
                            title="Доставляем кофе"
                            description={(
                                <>
                                    <div className="page-packages-list__splash-description">
                                        Привезем <strong className="page-packages-list__splash-b">сегодня</strong>
                                        , при&nbsp;сумме от&nbsp;1800₽&nbsp;
                                        <strong className="page-packages-list__splash-b">бесплатно</strong><br/>
                                    </div>

                                    <div className="page-packages-list__splash-controls">
                                        <Button
                                            className="page-packages-list__splash-button"
                                            variant="outlined"
                                            size="small"
                                            href="/delivery"
                                        >
                                            Подробнее
                                        </Button>
                                    </div>
                                </>
                            )}
                        />

                        <Container>
                            <PagePackagesList
                                addItem={addItemToBasket}
                                basket={basket}
                            />
                        </Container>
                    </Route>
                    <Route path="/packages/how-to-choose/" exact>
                        <LayoutHeader back="/" basket={basketSize} />
                        <Container>
                            <PagePackagesHowToChoose />
                        </Container>
                    </Route>
                    <Route path="/packages/:id">
                        <LayoutHeader backFn={() => window.history.back()} basket={basketSize} />
                        <Container>
                            <PagePackagesItem
                                type={PRODUCT_TYPE.COFFEE_IN_PACKAGE}
                                addItem={addItemToBasket}
                                basket={basket}
                            />
                        </Container>
                    </Route>
                    <Route path="/drip-coffee-packages/:id">
                        <LayoutHeader backFn={() => window.history.back()} basket={basketSize} />
                        <Container>
                            <PagePackagesItem
                                type={PRODUCT_TYPE.DRIP_COFFEE_PACKAGE}
                                addItem={addItemToBasket}
                                basket={basket}
                            />
                        </Container>
                    </Route>
                    <Route path="/new-year-package/:id">
                        <LayoutHeader backFn={() => window.history.back()} basket={basketSize} />
                        <Container>
                            <PagePackagesItem
                                type={PRODUCT_TYPE.NEW_YEAR_PACKAGE}
                                addItem={addItemToBasket}
                                basket={basket}
                            />
                        </Container>
                    </Route>
                    <Route path="/delivery" exact>
                        <LayoutHeader backFn={() => window.history.back()} basket={basketSize} />
                        <Container>
                            <PageDelivery />
                        </Container>
                    </Route>
                    <Route path="/privacy-policy" exact>
                        <LayoutHeader backFn={() => window.history.back()} basket={basketSize} />
                        <Container>
                            <PagePrivacyPolicy />
                        </Container>
                    </Route>
                    <Route path="/terms" exact>
                        <LayoutHeader backFn={() => window.history.back()} basket={basketSize} />
                        <Container>
                            <PageTerms />
                        </Container>
                    </Route>
                    <Route path="/requisites" exact>
                        <LayoutHeader backFn={() => window.history.back()} basket={basketSize} />
                        <Container>
                            <PageRequisites />
                        </Container>
                    </Route>
                    <Route path="/basket" exact>
                        <LayoutHeader backFn={() => window.history.back()} basket={basketSize} />
                        <Container>
                            <PageBasket
                                addItem={addItemToBasket}
                                removeItem={removeItemFromBasket}
                                basket={basket}
                            />
                        </Container>
                    </Route>
                    <Route path="/order/:id-:hash">
                        <LayoutHeader back="/" />
                        <Container>
                            <PageOrderItem />
                        </Container>
                    </Route>
                    <Route path="/payment/success" exact>
                        <LayoutHeader back="/" basket={basketSize} />
                        <Container>
                            <PagePaymentSuccess />
                        </Container>
                    </Route>
                    <Route path="/payment/fail" exact>
                        <LayoutHeader back="/" basket={basketSize} />
                        <Container>
                            <PagePaymentFail />
                        </Container>
                    </Route>
                    <Route
                        path="/error:code"
                        render={({ match }) => (
                            <>
                                <LayoutHeader back="/" basket={basketSize} />
                                <Container>
                                    <PageError code={match.params.code} />
                                </Container>
                            </>
                        )}
                    />
                    <Route path="*">
                        <LayoutHeader backFn={() => window.history.back()} basket={basketSize} />
                        <Container>
                            <PageError404 />
                        </Container>
                    </Route>
                </Switch>
                <LayoutFooter />
            </BrowserRouter>
        </React.Fragment>
    );
};
